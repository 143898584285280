import React, { useEffect, useState } from "react";
import Dropdown from "../components/Dropdown";
import Button from "../components/Button";
import appvariables from "../appvariables";
import { usHttp2 } from "../custom-hooks/use-http";
import Table from "../components/Table";
import { Link } from "react-router-dom";
import { loadingsliceactions } from "../store/loading-slice";
import { useDispatch } from "react-redux";

const RemarkBillsOC = () => {
    const [range, setRange] = useState("");
    const [tabledata, setTabledata] = useState([]);
    const [billsfortable, setBillsfortable] = useState([]);

    const masterdispatcher = useDispatch();
    const rangechange = (e) => {
        setRange(e.target.value);
    }

    const getRemarkBillsForRange = () => {
        masterdispatcher(loadingsliceactions.updateloading({'state': 'loading'}));
        let requestbody = {};
        requestbody["range"] = range;
        usHttp2(appvariables.apiurl + "bill/getremarkbills/", "POST", requestbody)
        .then((response) => response.json())
        .then((json) => 
        {
          setTabledata(json["bills"]);
        });
    }

    useEffect(() => {
        let rev_bills = [];
        for (let i = tabledata.length - 1; i >= 0; i--) {
          rev_bills.push(tabledata[i]);
        }
        setBillsfortable(rev_bills);
        masterdispatcher(loadingsliceactions.updateloading({'state': ''}));
      }, [tabledata]);

    const CreateLink = (billid) => {
        return (
          <Link
            to={`../../bill/getone/${billid}`}
            style={{
              backgroundColor: "black",
              color: "aliceblue",
              borderRadius: "20px",
              fontWeight: "300",
              textDecoration: "none",
              padding: "5px 15px",
              fontSize: "15px",
            }}
          >
            Details
          </Link>
        );
      };

    const TranformBillDate = (date) => {
        const monthNames = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        return (
          date.substring(8, 10) +
          "," +
          monthNames[Number(date.substring(5, 7)) - 1].substring(0, 3) +
          "," +
          date.substring(0, 4) +
          `(${date.substring(11, 16)})`
        );
      };

    const tablefields = [
        {
          name: 'By',
          key: 'billedby',
          filter: 1,
          cs: { width: "50px" }
        },
        { name: "customer", key: "customer", filter: 1, cs: { width: "150px" } },
        {
          name: "Date (Time)",
          key: "createddatetimestamp",
          transformer: TranformBillDate,
          cs: { width: "130px" },
        },
        { name: "Products", filter: 1, key: "products"},
        { name: "MRP", key: "totalfrommrp", cs: { width: "70px" } },
        { name: "Decided", key: "totalfinaldecided", cs: { width: "70px" } },
        { name: "Recieved", key: "totalrecieved", cs: { width: "70px" } },
        {
          name: "Link",
          key: "billid",
          transformer: CreateLink,
          cs: { width: "50px" },
        }
      ];

    return <div>
        <div style={{display: 'flex', alignItems: 'center', justifyItems: 'end'}}>
            <Dropdown
                dropdownstyle={"compact"}
                options={[
                    "Last 7 days",
                    "Last 14 days",
                    "Last 1 Month",
                    "Last 3 Months",
                    "Last 6 Months",
                    "Last 1 Year",
                    "Last 1.5 Years",
                    "Last 2 Years"
                    ]}
                id={"ds"}
                name={"range"}
                displayname={"Range"}
                changehandler={rangechange}
            />
            <Button
            clickhandler={getRemarkBillsForRange}
            disabled={range === ""}
            cs={{
                fontSize: "13px",
                fontWeight: 400,
                padding: "5px 10px",
                backgroundColor: range === "" && "rgba(0,0,0,0.6)",
                borderColor: range === "" && "rgba(0,0,0,0.6)",
            }}
            >Get</Button>
        </div>
        <Table
        data={billsfortable}
        tablefields={tablefields}
        decider={"credit"}
        rowstyling={{ backgroundColor: "rgb(255 73 73)" }}
      />
    </div>
};

export default RemarkBillsOC;